<template>
  <b-card>
    <b-overlay
      :show="!wiretransferPayments"
      rounded="sm"
    >
      <div class="invoice-register-table pt-1">
        <div class="float-left ir-btn-wrapper">
          <b-button
            v-b-modal.export-modal
            variant="primary"
            class="ir-btn billing-top-btn"
          >
            Export
          </b-button>
        </div>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          v-if="wiretransferPayments"
          ref="printMe"
          :columns="columns"
          :rows="wiretransferPayments"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          class="mt-2"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: user -->
            <span
              v-if="props.column.field === 'user'"
              style="'min-width: 150px;'"
              class="font-weight-bold"
            >
              <router-link
                :to="`/user/details/${props.row.userId}`"
              >
                {{ props.row.user }}
              </router-link>
            </span>

            <!-- Column: Invoice -->
            <span
              v-else-if="props.column.field === 'statusText'"
              class="font-weight-bold"
            >
              <span v-if="props.row.statusText==='Pending' && isNormalUser===false">
                <b-button
                  :id="`popover-button-sync`+props.row.id"
                  variant="primary"
                  style="width: 120px;"
                >{{ props.row.statusText }}</b-button>
                <b-popover
                  :target="`popover-button-sync`+props.row.id"
                  triggers="hover"
                  placement="bottom"
                >
                  <div style="margin:20px">
                    <span v-show="askAction">
                      <strong>Please select an action.</strong>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        style="margin:3px; width: 100%;"
                        @click="askConfirmAction"
                      >Confirm</button>
                      <button
                        type="button"
                        class="btn btn-outline-danger btn-sm"
                        style="margin:3px; width: 100%;"
                        @click="askRejectAction"
                      >Reject</button>
                    </span>
                    <span v-show="askConfirm">
                      <strong>Are you sure to confirm?</strong>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        style="margin:3px; width: 100%;"
                        :disabled="sendingConfirmYes"
                        @click="confirmYes(props.row.id)"
                      >
                        <span v-if="sendingConfirmYes">
                          <b-spinner
                            small
                            type="grow"
                          />
                          Confirming...
                        </span>
                        <span v-else>Yes</span>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-danger btn-sm"
                        style="margin:3px; width: 100%;"
                        @click="answerNo"
                      >No</button>
                    </span>
                    <span v-show="askReject">
                      <strong>Are you sure to reject?</strong>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        style="margin:3px; width: 100%;"
                        :disabled="sendingRejectYes"
                        @click="rejectYes(props.row.id)"
                      >
                        <span v-if="sendingConfirmYes">
                          <b-spinner
                            small
                            type="grow"
                          />
                          Rejecting...
                        </span>
                        <span v-else>Yes</span>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-danger btn-sm"
                        style="margin:3px; width: 100%;"
                        @click="answerNo"
                      >No</button>
                    </span>
                  </div>
                </b-popover>
              </span>
              <span
                v-if="props.row.statusTest === 'Pending'"
              >
                {{ props.row.statusText }}
              </span>
              <button
                v-if="props.row.statusText === 'Approved' && isUser() === false"
                class="btn btn-success"
                style="width: 120px;"
                @click="switchToPending(props.row.id)"
              > {{ props.row.statusText }}</button>
            </span>

            <!-- Column: Invoice -->
            <span
              v-else-if="props.column.field === 'customInvoice'"
              class="font-weight-bold"
            >
              <router-link
                :to="`${props.row.customInvoiceURI}/${props.row.customInvoiceLinkID}`"
              >
                {{ props.row.customInvoice }}
              </router-link>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','150']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
    <b-modal
      id="export-modal"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Export Table"
    >
      <div class="text-center">
        <b-button
          variant="primary"
          class="activation-btn"
          @click="downloadCSV"
        >
          To CSV
        </b-button><br>
        <download-excel
          style="margin:10px; width: 16rem"
          class="btn btn-primary"
          :data="json_data"
          :fields="json_fields"
          worksheet="WIRE Transfer"
          name="wire_transfer.xls"
        >
          To Excel
        </download-excel>
      </div>
    </b-modal>

  </b-card>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BPagination, BFormSelect, BCard, BOverlay, BButton, BBadge, BModal,
  BFormGroup, BFormInput, BPopover, BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import moment from 'moment'
import vSelect from 'vue-select'
import axios from '@/libs/axios'

import Vue from 'vue'
import JsonExcel from 'vue-json-excel'

Vue.component('downloadExcel', JsonExcel)

export default {
  components: {
    VueGoodTable,
    BPagination,
    BOverlay,
    BButton,
    BCard,
    BModal,
    BFormGroup,
    BFormSelect,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BPopover,
    BSpinner,
  },
  data() {
    return {
      isNormalUser: false,
      askAction: true,
      askConfirm: false,
      askReject: false,
      pageLength: 50,
      sendingConfirmYes: false,
      sendingRejectYes: false,
      columns: [
        {
          label: 'Request Date',
          field: 'customRequestDate',
          sortable: true,
          sortFn: this.sortFnReqDate,
          width: 'fit-content',
        },
        {
          label: 'User',
          field: 'user',
          sortable: true,
          sortFn: this.sortFnUser,
          width: 'fit-content',
        },
        {
          label: 'Company',
          field: 'company',
          sortable: true,
          sortFn: this.sortFnCompany,
          width: 'fit-content',
        },
        {
          label: 'Amount',
          field: 'customAmount',
          sortable: true,
          sortFn: this.sortFnAmount,
          width: 'fit-content',
        },
        {
          label: 'Status',
          field: 'statusText',
          sortable: false,
          width: 'fit-content',
        },
        {
          label: 'Invoice',
          field: 'customInvoice',
          sortable: true,
          sortFn: this.sortFnInvoice,
        },
        {
          label: 'Approval Date',
          field: 'customAppovalDate',
          sortable: true,
          sortFn: this.sortFnApproveDate,
          width: 'fit-content',
        },
      ],
      searchTerm: '',
      json_fields: {
        'Request Date': 'request_date',
        User: 'user',
        Amount: 'amount',
        Status: 'status',
        Invoice: 'invoice',
        'Approval date': 'approval_date',
      },
      json_data: [],
    }
  },
  computed: {
    wiretransferPayments() {
      return this.$store.state.invoice.wiretransferPayments
    },
  },
  created() {
    this.fetchWireTransferPayments()
  },
  mounted() {
    this.hideUserColumn()
    this.isNormalUser = this.isUser()
    if (this.wiretransferPayments) {
      const refreshId = setInterval(() => {
        const tableId = this.$refs.printMe
        if (tableId) {
          this.getExcelData()
          clearInterval(refreshId)
        }
      }, 1000)
    }
  },
  methods: {
    sortFnInvoice(x, y, col, rowX, rowY) {
      let x1 = rowX.customInvoice
      let y1 = rowY.customInvoice
      x1 = Number(x)
      y1 = Number(y)
      if (x1 < y1) return -1
      if (x1 > y1) return 1
      return 0
    },
    async switchToPending(id) {
      const payload = { id }
      this.sendingConfirmYes = true
      // Ask the user if it is sure to switch to pending
      this.confirmationAlert('Are you sure you want to switch the status to pending?')
        .then(async res => {
          if (res.isConfirmed) {
            const response = await axios.post('/api/wire_transfer/switch_to_pending', payload)
            if (response.status === 200) {
              this.notify({
                text: 'Payment has been successfully switched to pending.',
                variant: 'success',
              })
              this.$router.go(0)
            } else {
              this.notify({
                text: 'Something went wrong. Please try again.',
                variant: 'danger',
              })
            }
            this.sendingConfirmYes = false
          } else {
            this.sendingConfirmYes = false
            this.notify({
              text: 'Payment has not been switched to pending.',
              variant: 'danger',
            })
          }
        })
    },
    async confirmYes(id) {
      const payload = { id }
      this.sendingConfirmYes = true
      // wait 5 seconds and than refresh page
      setTimeout(() => {
        this.$router.go(0)
      }, 5000)
      const response = await axios.post('/api/wire_transfer/confirm_payment', payload)
      if (response.status === 200) {
        this.notify({
          text: 'Payment has been successfully verified and confirmed.',
          variant: 'success',
        })
        this.$router.go(0)
      }
      this.sendingConfirmYes = false
      this.$router.go(0)
    },
    async rejectYes(id) {
      const payload = { id }
      this.sendingRejectYes = true
      const response = await axios.post('/api/wire_transfer/reject_payment', payload)
      if (response.status === 200) {
        this.notify({
          text: 'Payment has been successfully rejected.',
          variant: 'success',
        })
        this.$router.go(0)
      }
      this.sendingRejectYes = false
      this.$router.go(0)
    },
    answerNo() {
      this.askAction = true
      this.askConfirm = false
      this.askReject = false
    },
    askConfirmAction() {
      this.askAction = false
      this.askConfirm = true
      this.askReject = false
    },
    askRejectAction() {
      this.askAction = false
      this.askConfirm = false
      this.askReject = true
    },
    hideUserColumn() {
      const isUser = this.isUser()
      if (isUser) {
        this.$set(this.columns[1], 'hidden', true)
        this.json_fields = {
          'Request Date': 'request_date',
          Amount: 'amount',
          Status: 'status',
          Invoice: 'invoice',
          'Approval date': 'approval_date',
        }
      }
    },
    isUser() {
      const user = this.$store.state.auth.userData
      if (user.userRole === 'User') return true
      return false
    },
    fetchWireTransferPayments() {
      const isUser = this.isUser()
      this.$store.dispatch('invoice/fetchWireTransferPayments', isUser)
        .catch(error => { console.log(error) })
    },
    getExcelData() {
      /* eslint-disable no-new-object */
      let el = new Object()
      /* eslint-enable no-new-object */
      el = this.$refs.printMe.$refs.table
      const isUser = this.isUser()
      Object.keys(el.rows).forEach(key => {
        if (key >= 1) {
          if (isUser) {
            const row = {
              request_date: el.rows[key].cells[0].textContent.trim(),
              amount: el.rows[key].cells[1].textContent.trim(),
              status: el.rows[key].cells[2].textContent.trim(),
              invoice: el.rows[key].cells[3].textContent.trim(),
              approval_date: el.rows[key].cells[4].textContent.trim(),
            }
            this.json_data.push(row)
          } else {
            const row = {
              request_date: el.rows[key].cells[0].textContent.trim(),
              user: el.rows[key].cells[1].textContent.trim(),
              amount: el.rows[key].cells[2].textContent.trim(),
              status: el.rows[key].cells[3].textContent.trim(),
              invoice: el.rows[key].cells[4].textContent.trim(),
              approval_date: el.rows[key].cells[5].textContent.trim(),
            }
            this.json_data.push(row)
          }
        }
      })
    },

    downloadCSV() {
      const content = this.getContents()
      const csv = this.convertToCSV(content)
      const filename = 'wire_transfer.csv'
      const blob = new Blob([`\ufeff${csv}`], { type: 'text/csv; charset=utf-8' })
      this.downloadFile(blob, filename)
    },

    downloadFile(blob, filename) {
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      link.id = 'download_to_download'
      document.body.appendChild(link)
      document.getElementById(link.id).click()

      // remove to make sure a new csv file will be downloaded and not the previous one
      const element = document.getElementById(link.id)
      element.remove()
    },

    getContents() {
      const user = this.isUser()
      let header = []
      if (user) {
        header = [
          'Request Date',
          'Amount',
          'Status',
          'Invoice',
          'Approval date',
        ]
      } else {
        header = [
          'Request Date',
          'User',
          'Amount',
          'Status',
          'Invoice',
          'Approval date',
        ]
      }

      /* eslint-disable no-new-object */
      let el = new Object()
      /* eslint-enable no-new-object */
      el = this.$refs.printMe.$refs.table

      const rows = []
      Object.keys(el.rows).forEach(key => {
        if (key >= 1) {
          if (user) {
            const row = []
            row.push(el.rows[key].cells[0].textContent.trim())
            row.push(el.rows[key].cells[1].textContent.trim())
            row.push(el.rows[key].cells[2].textContent.trim())
            row.push(el.rows[key].cells[3].textContent.trim())
            row.push(el.rows[key].cells[4].textContent.trim())
            rows.push(row)
          } else {
            const row = []
            row.push(el.rows[key].cells[0].textContent.trim())
            row.push(el.rows[key].cells[1].textContent.trim())
            row.push(el.rows[key].cells[2].textContent.trim())
            row.push(el.rows[key].cells[3].textContent.trim())
            row.push(el.rows[key].cells[4].textContent.trim())
            row.push(el.rows[key].cells[5].textContent.trim())
            rows.push(row)
          }
        }
      })

      rows.unshift(header)
      return JSON.stringify(rows)
    },

    convertToCSV(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
      let str = ''
      for (let i = 0; i < array.length; i++) { // eslint-disable-line
        let line = ''
        for (const index in array[i]) { // eslint-disable-line
          if (line !== '') line += ','
          line += array[i][index]
        }
        str += `${line}\r\n` // eslint-disable-line
      }
      return str
    },
    sortFnCompany(x, y, col, rowX, rowY) { // eslint-disable-line
      let value1 = rowX.company.trim()// get x
      let value2 = rowY.company.trim()// get y
      // remove the numbers from the string
      value1 = value1.replace(/[0-9]/g, '')
      value2 = value2.replace(/[0-9]/g, '')
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
    sortFnUser(x, y, col, rowX, rowY) { // eslint-disable-line
      let x1 = rowX.email
      let y1 = rowY.email
      x1 = String(x1).toLowerCase()
      y1 = String(y1).toLowerCase()
      // remove the numbers from the string
      x1 = x1.replace(/[0-9]/g, '')
      y1 = y1.replace(/[0-9]/g, '')
      if (x1 < y1) return -1
      if (x1 > y1) return 1
      return 0
    },
    sortFnAmount(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = parseFloat(rowX.amount)// get x
      const value2 = parseFloat(rowY.amount)// get y
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
    sortFnApproveDate(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = Date.parse(this.filterFormatDate(rowX.approvalDate))// get x
      const value2 = Date.parse(this.filterFormatDate(rowY.approvalDate))// get y
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
    sortFnReqDate(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = Date.parse(this.filterFormatDate(rowX.requestDate))// get x
      const value2 = Date.parse(this.filterFormatDate(rowY.requestDate))// get y
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    getAmount(item) {
      const exchange_rate = 4.9264

      const value = parseFloat(item.payment_value)
      const cur = item.currency.toUpperCase()
      if (cur === 'RON') return value / exchange_rate
      return value
    },

    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker {
  width: 100% !important;
}

.invoice-register-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  td {
    height: 62px;
  }
}

.ir-btn-wrapper {
  width: 30rem;

  .ir-btn {
    width: 100%;
    max-width: 148px;
  }
}

.activation-btn {
  width: 100%;
  max-width: 16rem;
}

.modal .modal-header .close{
  margin: 2px
}
</style>
